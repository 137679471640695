<template>
  <v-container>
    <v-row>
        <v-col cols="12" class="text-center">
          <header-vue/>
        </v-col>
        <v-col cols="12">
            <v-card
                class="mx-auto px-5 py-5"
                outlined
            >
                <h2 class="font-weight-bold">
                404
                </h2>
                <small class="subheading font-weight-regular">
                Page Not Found
                </small>                
            </v-card>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HeaderVue from '../../components/Header.vue'
  export default {
    name: 'PageNotFound',
    data: () => ({
        //
    }),
    components: {
        HeaderVue
    },
    mounted(){
        //      
    },
    methods: {
        //
    },
  }
</script>
